import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Form, Button, Alert, InputGroup } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { API_URL } from '../config/config';
import axios from 'axios';
import { toast } from 'react-toastify';

const Login = () => {
  const userProfile = JSON.parse(localStorage.getItem('userProfile'));
  const token = localStorage.getItem('token');
  const dispatch = useDispatch();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState('');
  const [showHide, setShowHide] = useState(true)
  const navigate = useNavigate();



  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!username || !password) {
      setError('Please fill in all fields');
      return;
    }

    try {
      const response = await axios.post(`${API_URL}authenticate`, {
        uid: username,
        password: password
      });
      if (response.status === 200) {
        const jwtToken = response.data.data.jwtToken;
        console.log(response, "responseresponseresponseresponse")
        // Save the JWT token to local storage
        localStorage.setItem('token', jwtToken);
        navigate('/userProfiles');
      } else {
        setError('Login failed. Please check your credentials.');
      }
    } catch (error) {
      setError('An error occurred while logging in. Please try again.');
    }
  };


  const showHideHandle = () => {
    setShowHide(!showHide)
  }


  useEffect(() =>{
    if(token && userProfile?.userRole !== "ADMIN"){
        toast.error("User not valid")
    }
  },[userProfile])

  return (
    <div className='loginForm'>
      {/* <h3>Admin Login </h3> */}
      <img className="adminLogo" src={process.env.PUBLIC_URL + 'images/adminLogo.svg'} alt="admin Logo" />
      <Form onSubmit={handleSubmit}>
      <h3>Admin Login </h3>
        {error && <Alert variant="danger">{error}</Alert>}
        <Form.Group controlId="formUsername" className='mb-3'>
          <Form.Label className='required'>Username</Form.Label>
          <Form.Control
            type="text"
            placeholder="Enter username"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
          />
        </Form.Group>
        <Form.Group controlId="formPassword">
          <Form.Label className='required'>Password</Form.Label>
          <InputGroup className="mb-3">
            <Form.Control
              type={showHide ? "password" : "text"}
              placeholder="Password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            <InputGroup.Text>{showHide ?
              <i onClick={showHideHandle} class="bi bi-eye-slash-fill"></i>
              :
              <i onClick={showHideHandle} class="bi bi-eye-fill"></i>
            }</InputGroup.Text>

          </InputGroup>

        </Form.Group>
        <Form.Group className="mb-3">
          <Form.Check type="checkbox" label="Remember Me" />
        </Form.Group>
        <Button variant="primary" type="submit">
          Login
        </Button>
      </Form>

    </div>

  );
};

export default Login;
