import React from 'react';
import { Button, Container, Row, Col, } from "react-bootstrap";
import Zone from './Zone';
import { Tab, Tabs } from 'react-bootstrap';
import Team from './Team';
import Role from './Role';
import LoggedinProfile from '../../components/LoggedinProfile';
import { Link } from 'react-router-dom';
function ManageTeam() {


  return (

    <Container className={`profileWrapper container-fluid"}`}>
      <Row className={`headSec`}>
        <Col>
          <div className="mb-3 headTop">
            <div className="useHead">
              <h1>
                <>
                  <Link to="/userProfiles">
                    <img src={process.env.PUBLIC_URL + 'images/sidebar/white_back_icon.svg'} alt="backbutton" />
                  </Link>
                  Back
                </>
              </h1>
              <div className="forMobile">
                <LoggedinProfile />
              </div>
            </div>
            <div className="adminDetail">
              <div className="forWeb">
                <LoggedinProfile />
              </div>
            </div>
          </div>
        </Col>
      </Row>

      <Tabs
        defaultActiveKey="zone"
        id="uncontrolled-tab-example"
        className="mb-3"
      >
        <Tab eventKey="zone" title="Zone">
          <Zone />
        </Tab>
        <Tab eventKey="team" title="Team">
          <Team />
        </Tab>
        <Tab eventKey="role" title="Role">
          <Role />
        </Tab>

      </Tabs>
    </Container>
  );
}

export default ManageTeam;
