import React, { useState, useEffect } from 'react';
import { getDataWithAuth } from '../../api/api';
import DeleteProfile from '../modal/DeleteProfile';
import axios from 'axios';
import { API_URL } from '../../config/config';
import { toast } from "react-toastify";
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
const Zone = () => {
    const token = localStorage.getItem('token');
    const [zones, setZones] = useState([]);
    const [branches, setBranches] = useState({});
    const [areaCodes, setAreaCodes] = useState({});
    const [expandedZones, setExpandedZones] = useState({});
    const [expandedBranches, setExpandedBranches] = useState({});
    const [expandedAreaCodes, setExpandedAreaCodes] = useState({}); // New state for area codes
    const [editingItem, setEditingItem] = useState(null);
    const [newLabel, setNewLabel] = useState('');
    const [addingItem, setAddingItem] = useState(null);
    const [newItemLabel, setNewItemLabel] = useState('');
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [itemToDelete, setItemToDelete] = useState(null);

    console.log(zones, "zones", branches, "branches")

    useEffect(() => {
        fetchZones();
    }, []);

    const fetchZones = async () => {
        try {
            const response = await getDataWithAuth('admin/api/zone/list');
            const options = response.data.map((zone) => ({
                value: zone.id,
                label: zone.name,
            }));
            setZones(options);
        } catch (error) {
            console.error('Error fetching zones', error);
        }
    };

    const fetchBranches = async (zoneId) => {
        if (branches[zoneId]) return; // Skip fetching if already fetched
        try {
            const response = await getDataWithAuth(`admin/api/branch/list?zoneId=${zoneId}`);
            const options = response.data.map((branch) => ({
                value: branch.id,
                label: branch.branchName,
            }));
            setBranches((prevBranches) => ({
                ...prevBranches,
                [zoneId]: options,
            }));
        } catch (error) {
            console.error('Error fetching branches', error);
        }
    };

    const fetchAreaCodes = async (branchId) => {
        if (areaCodes[branchId]) return; // Skip fetching if already fetched
        try {
            const response = await getDataWithAuth(`admin/api/areacodes/list?branchId=${branchId}`);
            const options = response.data.map((areaCode) => ({
                value: areaCode.id,
                label: areaCode.areaCode,
            }));
            setAreaCodes((prevAreaCodes) => ({
                ...prevAreaCodes,
                [branchId]: options,
            }));
        } catch (error) {
            console.error('Error fetching area codes', error);
        }
    };

    const toggleZoneExpansion = (zoneId) => {
        setExpandedZones((prevExpandedZones) => ({
            ...prevExpandedZones,
            [zoneId]: !prevExpandedZones[zoneId],
        }));

        // Fetch branches when a zone is expanded
        if (!expandedZones[zoneId]) {
            fetchBranches(zoneId);
        }
    };

    const toggleBranchExpansion = (branchId) => {
        setExpandedBranches((prevExpandedBranches) => ({
            ...prevExpandedBranches,
            [branchId]: !prevExpandedBranches[branchId],
        }));

        // Fetch area codes when a branch is expanded
        if (!expandedBranches[branchId]) {
            fetchAreaCodes(branchId);
        }
    };

    // const toggleAreaCodeExpansion = (areaCodeId) => {
    //     setExpandedAreaCodes((prevExpandedAreaCodes) => ({
    //         ...prevExpandedAreaCodes,
    //         [areaCodeId]: !prevExpandedAreaCodes[areaCodeId],
    //     }));
    // };



    const startEditing = (type, item) => {
        setEditingItem({ type, value: item.value, zoneId: item.zoneId, branchId: item.branchId });
        setNewLabel(item.label);
    };

    const saveEdit = async () => {
        if (!editingItem) return;
        const { type, value, zoneId, branchId } = editingItem;
        let url = '';

        const headers = {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        };


        try {
            switch (type) {
                case 'zone':
                    const zoneParam = { id: value, name: newLabel };
                    const zoneResponse = await axios.put(`${API_URL}admin/api/zone/update`, zoneParam, { headers });
                    const updateZone = zoneResponse.data;
                    if (updateZone.resCode === 200) {
                        setZones(zones.map((zone) => (zone.value === value ? { ...zone, label: newLabel } : zone)));
                        toast.success(updateZone.message);
                    } else {
                        alert(`Error: ${updateZone.message}`);
                    }
                    break;

                case 'branch':
                    const branchpParams = { id: value, branchName: newLabel, zoneId };
                    url = `${API_URL}admin/api/branch/update`;
                    const branchResponse = await axios.put(url, branchpParams, { headers });
                    const updateBranch = branchResponse.data;
                    if (updateBranch.resCode === 200) {
                        setBranches((prevBranches) => ({
                            ...prevBranches,
                            [zoneId]: prevBranches[zoneId].map((branch) =>
                                branch.value === value ? { ...branch, label: newLabel } : branch
                            ),
                        }));
                        toast.success(updateBranch.message);
                    } else {
                        alert(`Error: ${updateBranch.message}`);
                    }
                    break;

                case 'area code':
                    const areaCodeParams = { id: value, areaCode: newLabel, branchId };
                    url = `${API_URL}admin/api/areacode/update`;
                    const areaCodeResponse = await axios.put(url, areaCodeParams, { headers });
                    const updateAreaCode = areaCodeResponse.data;
                    if (updateAreaCode.resCode === 200) {
                        setAreaCodes((prevAreaCodes) => ({
                            ...prevAreaCodes,
                            [branchId]: prevAreaCodes[branchId].map((code) =>
                                code.value === value ? { ...code, label: newLabel } : code
                            ),
                        }));
                        toast.success(updateAreaCode.message);
                    } else {
                        alert(`Error: ${updateAreaCode.message}`);
                    }
                    break;

                default:
                    break;
            }

            setEditingItem(null);
            setNewLabel('');
        } catch (error) {
            console.error(`Failed to update ${type}:`, error);
        }
    };



    const closeDeleteModal = () => {
        setShowDeleteModal(false);
        setItemToDelete(null);
    };


    const deleteItem = async () => {
        if (!itemToDelete) return;

        const { type, item } = itemToDelete;
        let url = '';

        const headers = {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        };

        try {
            let deleteResponse;

            switch (type) {
                case 'zone':
                    url = `${API_URL}admin/api/zone/delete/${item.value}`;
                    deleteResponse = await axios.delete(url, { headers });

                    if (deleteResponse.data.resCode === 200) {
                        setZones((prevZones) => prevZones.filter((zone) => zone.value !== item.value));
                        toast.success(deleteResponse.data.message);
                    } else {
                        alert(`Error: ${deleteResponse.data.message}`);
                    }
                    break;

                case 'branch':
                    url = `${API_URL}admin/api/branch/delete/${item.value}`;
                    deleteResponse = await axios.delete(url, { headers });

                    if (deleteResponse.data.resCode === 200) {
                        const updatedBranches = { ...branches };
                        Object.keys(updatedBranches).forEach((key) => {
                            updatedBranches[key] = updatedBranches[key].filter((branch) => branch.value !== item.value);
                        });
                        setBranches(updatedBranches);
                        toast.success(deleteResponse.data.message);
                    } else {
                        alert(`Error: ${deleteResponse.data.message}`);
                    }
                    break;

                case 'area code':
                    url = `${API_URL}admin/api/areacode/delete/${item.value}`;
                    deleteResponse = await axios.delete(url, { headers });

                    if (deleteResponse.data.resCode === 200) {
                        const updatedAreaCodes = { ...areaCodes };
                        Object.keys(updatedAreaCodes).forEach((key) => {
                            updatedAreaCodes[key] = updatedAreaCodes[key].filter((code) => code.value !== item.value);
                        });
                        setAreaCodes(updatedAreaCodes);
                        toast.success(deleteResponse.data.message);
                    } else {
                        alert(`Error: ${deleteResponse.data.message}`);
                    }
                    break;

                default:
                    break;
            }

            closeDeleteModal();
        } catch (error) {
            console.error(`Failed to delete ${type}:`, error);
            alert(`An error occurred while deleting the ${type}. Please try again.`);
        }
    };


    const startAddingItem = (type, parentId) => {

        setAddingItem({ type, parentId });
        setNewItemLabel('');
    };


    const saveNewItem = async () => {
        if (!addingItem) return;

        const { type, parentId } = addingItem;
        console.log(addingItem, "addingItem");
        let params = {};
        const headers = {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        };

        try {
            switch (type) {
                case 'zone':
                    params = { name: newItemLabel };
                    const zoneResponse = await axios.post(`${API_URL}admin/api/zone/create`, params, { headers });
                    const newZone = zoneResponse.data;
                    setZones((prevZones) => [
                        ...prevZones,
                        { value: newZone.data.id, label: newZone.data.name }
                    ]);
                    break;

                case 'branch':
                    params = {
                        branchName: newItemLabel,
                        zoneId: parentId,
                    };
                    const branchResponse = await axios.post(`${API_URL}admin/api/branch/create`, params, { headers });
                    const newBranch = branchResponse.data;
                    console.log(newBranch, "newBranch")
                    setBranches((prevBranches) => ({
                        ...prevBranches,
                        [parentId]: [
                            ...(prevBranches[parentId] || []),
                            { value: newBranch.data.id, label: newBranch.data.branchName }
                        ],
                    }));
                    break;

                case 'area code':
                    params = {
                        areaCode: newItemLabel,
                        branchId: parentId,
                    };
                    const areaCodeResponse = await axios.post(`${API_URL}admin/api/areacode/create`, params, { headers });
                    const newAreaCode = areaCodeResponse.data;
                    setAreaCodes((prevAreaCodes) => ({
                        ...prevAreaCodes,
                        [parentId]: [
                            ...(prevAreaCodes[parentId] || []),
                            { value: newAreaCode.data.id, label: newAreaCode.data.areaCode }
                        ],
                    }));
                    break;

                default:
                    break;
            }

            setAddingItem(null);
            setNewItemLabel('');
        } catch (error) {
            toast.error(error.response.data.message);
            console.error(`Failed to create ${type}:`, error.response, error.response.data.message);
        }
    };




    const openDeleteModal = (type, item) => {
        console.log(type, item, "ype, item")
        setItemToDelete({ type, item });
        setShowDeleteModal(true);
    };

    return (
        <>
            <table className="table table-striped table-hover">
                <thead>
                    <tr>
                        <th>Zone</th>
                        <th className='text-end'>
                            <button onClick={() => startAddingItem('zone')} type="button" className="createBtn btn btn-primary addMoreBtn">
                                <img src="images/sidebar/plus_icon.svg" alt="Add" /> Add Zone
                            </button>
                            Actions
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {addingItem?.type === 'zone' && (
                        <tr>
                            <td>
                                <input
                                    type="text"
                                    value={newItemLabel}
                                    onChange={(e) => setNewItemLabel(e.target.value)}
                                    placeholder="Enter new zone name"
                                    className="form-control d-inline-block me-2"
                                />
                            </td>
                            <td className='text-end'>
                                <button className="btn btn-primary saveBtn" onClick={saveNewItem}>Save</button>
                                <button className="btn btn-secondary" onClick={() => setAddingItem(null)}>Cancel</button>
                            </td>
                        </tr>
                    )}
                    {zones.map((zone) => (
                        <React.Fragment key={zone.value}>
                            <tr>
                                <td>
                                    {editingItem?.type === 'zone' && editingItem.value === zone.value ? (
                                        <input
                                            type="text"
                                            value={newLabel}
                                            onChange={(e) => setNewLabel(e.target.value)}
                                            placeholder="Edit zone name"
                                            className="form-control d-inline-block me-2"
                                        />
                                    ) : (
                                        <span className='toggleLink' onClick={() => toggleZoneExpansion(zone.value)}>
                                            {expandedZones[zone.value] ? <i class="bi bi-dash-lg"></i> : <i class="bi bi-plus-lg"></i>}
                                            {zone.label}</span>
                                    )}
                                </td>
                                <td className='text-end'>
                                    {editingItem?.type === 'zone' && editingItem.value === zone.value ? (
                                        <>
                                            <button className="btn btn-primary saveBtn" onClick={saveEdit}>Save</button>
                                            <button className="btn btn-secondary" onClick={() => setEditingItem(null)}>Cancel</button>
                                        </>
                                    ) : (
                                        <>
                                            <button onClick={() => startAddingItem('branch', zone.value)} type="button" className="createBtn btn btn-primary addMoreBtn">
                                                <img src="images/sidebar/plus_icon.svg" alt="Add" /> Add Branch
                                            </button>
                                            <OverlayTrigger
                                                placement="top"
                                                overlay={<Tooltip>Edit</Tooltip>}
                                            >
                                                <button className="btn btn-primary editBtn" onClick={() => startEditing('zone', zone)}>
                                                    <img className="userImg" src="images/sidebar/edit_icon.svg" alt="edit_icon" />
                                                </button>
                                            </OverlayTrigger>
                                            <OverlayTrigger
                                                placement="top"
                                                overlay={<Tooltip>Delete</Tooltip>}
                                            >
                                                <button className="btn deleteBtn" onClick={() => openDeleteModal('zone', zone)}>
                                                    <img src={process.env.PUBLIC_URL + 'images/sidebar/delete_icon.svg'} alt="delete" />
                                                </button>
                                            </OverlayTrigger>


                                        </>
                                    )}
                                </td>
                            </tr>
                            {addingItem?.type === 'branch' && addingItem?.parentId === zone.value && (
                                <tr>
                                    <td style={{ paddingLeft: '30px' }}>
                                        <input
                                            type="text"
                                            value={newItemLabel}
                                            onChange={(e) => setNewItemLabel(e.target.value)}
                                            placeholder="Enter new branch name"
                                            className="form-control d-inline-block me-2"
                                        />
                                    </td>
                                    <td className='text-end'>
                                        <button className="btn btn-primary saveBtn" onClick={saveNewItem}>Save</button>
                                        <button className="btn btn-secondary" onClick={() => setAddingItem(null)}>Cancel</button>
                                    </td>
                                </tr>
                            )}
                            {expandedZones[zone.value] && branches[zone.value] && (
                                <tr>
                                    <td colSpan="2" className='pe-0 pt-0 pb-0 indentTable table-active'>
                                        <table className="table mb-0">
                                            <tbody>
                                                {branches[zone.value].map((branch) => (
                                                    <React.Fragment key={branch.value}>
                                                        <tr>
                                                            <td>
                                                                {editingItem?.type === 'branch' && editingItem.value === branch.value ? (
                                                                    <input
                                                                        type="text"
                                                                        value={newLabel}
                                                                        onChange={(e) => setNewLabel(e.target.value)}
                                                                        placeholder="Edit branch name"
                                                                        className="form-control d-inline-block me-2"
                                                                    />
                                                                ) : (
                                                                    <span className='toggleLink' onClick={() => toggleBranchExpansion(branch.value)}>
                                                                        {expandedBranches[branch.value] ? <i class="bi bi-dash-lg"></i> : <i class="bi bi-plus-lg"></i>}
                                                                        {branch.label}</span>
                                                                )}
                                                            </td>
                                                            <td className='text-end'>
                                                                {editingItem?.type === 'branch' && editingItem.value === branch.value ? (
                                                                    <>
                                                                        <button className="btn btn-primary saveBtn" onClick={saveEdit}>Save</button>
                                                                        <button className="btn btn-secondary" onClick={() => setEditingItem(null)}>Cancel</button>
                                                                    </>
                                                                ) : (
                                                                    <>
                                                                        <button type="button" className="createBtn btn btn-primary addMoreBtn" onClick={() => startAddingItem('area code', branch.value)}>
                                                                            <img src="images/sidebar/plus_icon.svg" alt="Add" /> Add Area Code
                                                                        </button>
                                                                        <OverlayTrigger
                                                                            placement="top"
                                                                            overlay={<Tooltip>Edit</Tooltip>}
                                                                        >
                                                                            <button className="btn btn-primary editBtn" onClick={() => startEditing('branch', { ...branch, zoneId: zone.value })}>
                                                                                <img className="userImg" src="images/sidebar/edit_icon.svg" alt="edit_icon" />
                                                                            </button>
                                                                        </OverlayTrigger>
                                                                        <OverlayTrigger
                                                                            placement="top"
                                                                            overlay={<Tooltip>Delete</Tooltip>}
                                                                        >
                                                                            <button className="btn deleteBtn" onClick={() => openDeleteModal('branch', branch)}>
                                                                                <img src={process.env.PUBLIC_URL + 'images/sidebar/delete_icon.svg'} alt="delete" />
                                                                            </button>
                                                                        </OverlayTrigger>



                                                                    </>
                                                                )}
                                                            </td>
                                                        </tr>
                                                        {addingItem?.type === 'area code' && addingItem?.parentId === branch.value && (
                                                            <tr>
                                                                <td style={{ paddingLeft: '30px' }}>
                                                                    <input
                                                                        type="text"
                                                                        value={newItemLabel}
                                                                        onChange={(e) => setNewItemLabel(e.target.value)}
                                                                        placeholder="Enter new area code"
                                                                        className="form-control d-inline-block me-2"
                                                                    />
                                                                </td>
                                                                <td className='text-end'>
                                                                    <button className="btn btn-primary saveBtn" onClick={saveNewItem}>Save</button>
                                                                    <button className="btn btn-secondary" onClick={() => setAddingItem(null)}>Cancel</button>
                                                                </td>
                                                            </tr>
                                                        )}
                                                        {expandedBranches[branch.value] && areaCodes[branch.value] && (
                                                            <React.Fragment>
                                                                <tr>
                                                                    <td colSpan="2" className='pe-0 pb-0 pt-0 indentTable table-active'>
                                                                        <table className="table mb-0">
                                                                            <tbody>

                                                                                {areaCodes[branch.value].map((code) => (
                                                                                    <React.Fragment key={code.value}>
                                                                                        <tr>
                                                                                            <td>
                                                                                                {editingItem?.type === 'area code' && editingItem.value === code.value ? (
                                                                                                    <>
                                                                                                        <input
                                                                                                            type="text"
                                                                                                            value={newLabel}
                                                                                                            onChange={(e) => setNewLabel(e.target.value)}
                                                                                                            placeholder="Enter new area code"
                                                                                                            className="form-control d-inline-block me-2"
                                                                                                        />

                                                                                                    </>
                                                                                                ) : (
                                                                                                    <span>{code.label}</span>
                                                                                                )}
                                                                                            </td>
                                                                                            <td className='text-end'>
                                                                                                {editingItem?.type === 'area code' && editingItem.value === code.value ? (
                                                                                                    <>
                                                                                                        <button className="btn btn-primary saveBtn" onClick={saveEdit}>Save</button>
                                                                                                        <button className="btn btn-secondary" onClick={() => setEditingItem(null)}>Cancel</button>
                                                                                                    </>
                                                                                                ) : (
                                                                                                    <>
                                                                                                        <OverlayTrigger
                                                                                                            placement="top"
                                                                                                            overlay={<Tooltip>Edit</Tooltip>}
                                                                                                        >
                                                                                                            <button className="btn btn-primary editBtn" onClick={() => startEditing('area code', { ...code, branchId: branch.value })}>
                                                                                                                <img className="userImg" src="images/sidebar/edit_icon.svg" alt="edit_icon" />
                                                                                                            </button>
                                                                                                        </OverlayTrigger>
                                                                                                        <OverlayTrigger
                                                                                                            placement="top"
                                                                                                            overlay={<Tooltip>Edit</Tooltip>}
                                                                                                        >
                                                                                                            <button className="btn deleteBtn" onClick={() => openDeleteModal('area code', code)}>
                                                                                                                <img src={process.env.PUBLIC_URL + 'images/sidebar/delete_icon.svg'} alt="delete" />
                                                                                                            </button>
                                                                                                        </OverlayTrigger>


                                                                                                    </>
                                                                                                )}
                                                                                            </td>
                                                                                        </tr>
                                                                                    </React.Fragment>
                                                                                ))}
                                                                            </tbody>
                                                                        </table>
                                                                    </td>
                                                                </tr>
                                                            </React.Fragment>
                                                        )}
                                                    </React.Fragment>
                                                ))}
                                            </tbody>
                                        </table>
                                    </td>
                                </tr>
                            )}
                        </React.Fragment>
                    ))}
                </tbody>
            </table>
            {showDeleteModal && <DeleteProfile
                show={showDeleteModal}
                hide={closeDeleteModal}
                onConfirm={deleteItem}
                item={itemToDelete}
            />}
        </>


    );
}

export default Zone;
