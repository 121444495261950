


import React, { useEffect, useState } from "react";
import { Button, Card, Col, Dropdown, ProgressBar, Row } from "react-bootstrap";
import DeleteProfile from "../pages/modal/DeleteProfile";
import CreateProfileModal from "../pages/modal/CreateProfileModal";
import axios from 'axios'; // Ensure axios is installed
import { getDataWithAuth, postDataWithAuth } from "../api/api";
import { toast } from "react-toastify";
import { API_URL } from "../config/config";

const UserDetail = ({ user, backListHandle }) => {
    const token = "Esd4fsdfsdcRFr3fDdfgdfG!Fddvdfg^fgbfvR45GGDDFVv5f-fgdvdfg$FdrfDG";
    const tokenHead = localStorage.getItem('token');
    const [showDeleteMOdal, setShowDeleteMOdal] = useState(false);
    const [createProfileModal, setCreateProfileModal] = useState(false);
    const [userValue, setUserValue] = useState();
    console.log(userValue, "userValue")
    const [imagePreview, setImagePreview] = useState('https://adminlte.io/themes/AdminLTE/dist/img/user3-128x128.jpg');
    const [imageFile, setImageFile] = useState(null);
    const handleClose = () => setShowDeleteMOdal(false);
    const handleShow = () => setShowDeleteMOdal(true);
    const closeCreateProfile = () => setCreateProfileModal(false);
    const handleImageChange = async (event) => {
        const file = event.target.files[0];
        if (file) {
            setImageFile(file);
            const reader = new FileReader();
            reader.onload = (e) => {
                setImagePreview(e.target.result);
            };
            reader.readAsDataURL(file);
            console.log(file, "file");

            // Reset file input value to allow the same file to be selected again
            event.target.value = null;

            // Trigger the API call to upload the image
            const formData = new FormData();
            formData.append('uid', userValue.uid); // Ensure this UID is correct
            formData.append('image', file);

            try {
                const response = await axios.post(
                    'https://ypss-pb.org/uid-photos/uploadVolunteerImg.php',
                    formData,
                    {
                        headers: {
                            'Authorization': `${token}`,
                            'Content-Type': 'multipart/form-data'
                            // Do not set 'Content-Type' here
                        }
                    }

                );
                console.log('Upload successful:', response.data);
                // Assuming the API returns the URL of the uploaded image
                setUserValue((prev) => ({ ...prev, imgUrl: response.imgUrl }));
                // localStorage.setItem('profileImg', response.imgUrl);

            } catch (error) {
                if (error.response) {
                    console.error('Error response:', error.response.data);
                    console.error('Error status:', error.response.status);
                    console.error('Error headers:', error.response.headers);
                } else if (error.request) {
                    console.error('Error request:', error.request);
                } else {
                    console.error('Error message:', error.message);
                }
                console.error('Error config:', error.config);
            }
        }
    };





    const singleUserProfile = async () => {
        try {
            const response = await getDataWithAuth(`admin/api/user/usersFilter?uid=${user.uid}`);
            if (response && response.data) {

                const { users } = response.data;
                console.log(users, "users", response.data)
                const user = users[0];
                setUserValue(user);
            }
        } catch (error) {
            console.error("Error fetching user profile", error);
        }
    };


    useEffect(() => {
        if (user.uid) {
            singleUserProfile();
        }
    }, [user]);





    const fallbackImageUrl = process.env.PUBLIC_URL + '/images/invite.png';
    const ImageWithFallback = ({ src, fallbackSrc, alt, className }) => {
        const [imgSrc, setImgSrc] = useState(src);
        const handleError = () => {
            setImgSrc(fallbackSrc);
        };
        return (
            <img className="profile-user-img img-responsive img-circle userImg" src={imgSrc} alt={alt} onError={handleError} />
        );
    };

    const editHandle = (section, edit) => {
        setCreateProfileModal({ key: "true", section, data: userValue, edit });
        setCreateProfileModal(prev => ({
            ...prev,
            onSave: () => {
                singleUserProfile();
            }
        }));
    };

    useEffect(() => {
        if (!createProfileModal) {
            singleUserProfile();
        }
    }, [createProfileModal]);


    const userBlock = async () => {
        let param = {
            uid: userValue.uid,
            status: 0,
        };

        const headers = {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${tokenHead}`
        };

        try {
            const response = await axios.put(`${API_URL}admin/api/user/updateUser`, param, { headers })
            if (response.status === 200 || response.status === 201) {
                toast.success("User Blocked");
            }
        } catch (error) {
            console.error("Error submitting the form", error);
        }
    }

    const userActive = async () => {
        let param = JSON.stringify(
            {
            uid: userValue.uid,
            status: 1,
        });

        const headers = {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${tokenHead}`
        };

        try {
            const response =  await axios.put(`${API_URL}admin/api/user/updateUser`, param, { headers })
            if (response.status === 200 || response.status === 201) {
                toast.success("User Active");
            }
        } catch (error) {
            console.error("Error submitting the form", error);
        }
    }




    return (
        <>
            <section className="profileDetail">
                <div className="detailHead">
                    <div className="profileMenu">
                        <button className="backButton" onClick={backListHandle}>
                            <img src={process.env.PUBLIC_URL + 'images/sidebar/white_back_icon.svg'} alt="backbutton" />
                        </button>
                        <div className="customDropdown">
                            <Dropdown>
                                <Dropdown.Toggle id="dropdown-basic">
                                    <i className="bi bi-three-dots-vertical"></i>
                                </Dropdown.Toggle>
                                <Dropdown.Menu>
                                    <ul>
                                        <li className="active">
                                            <button onClick={userActive} className="btn">
                                                <img src={process.env.PUBLIC_URL + 'images/sidebar/active_icon.svg'} alt="active" />Active
                                            </button>
                                        </li>
                                        <li className="block">
                                            <button onClick={() => userBlock()} className="btn">
                                                <img src={process.env.PUBLIC_URL + 'images/sidebar/block_icon.svg'} alt="block" />Block
                                            </button>
                                        </li>
                                        <li className="delete">
                                            <button className="btn" onClick={handleShow}>
                                                <img src={process.env.PUBLIC_URL + 'images/sidebar/delete_icon_gray.svg'} alt="delete" />Delete
                                            </button>
                                        </li>
                                    </ul>
                                </Dropdown.Menu>
                            </Dropdown>
                        </div>
                    </div>
                </div>
                <div className="avatar-upload">
                    <div className="avatar-edit">
                        <input
                            type="file"
                            id="imageUpload"
                            accept=".png, .jpg, .jpeg"
                            onChange={handleImageChange}
                        />
                        <label htmlFor="imageUpload">
                            <img width="15px" src={process.env.PUBLIC_URL + 'images/pages/camra_icon.svg'} alt="Schedule" />
                        </label>
                    </div>
                    <div className="avatar-preview">
                        <div className="profileImgOuter">
                            {userValue?.imgUrl ? (
                                <ImageWithFallback
                                    style={{ display: imagePreview ? 'none' : 'block' }}
                                    src={userValue?.imgUrl}
                                    fallbackSrc={fallbackImageUrl}
                                    alt="Invite Icon"
                                />
                            ) : (
                                <img
                                    className="profile-user-img img-responsive img-circle userImg"
                                    src={fallbackImageUrl}
                                    alt="Invite Icon"
                                />
                            )}
                        </div>
                    </div>
                </div>
                <div className="userName">
                    <label>{userValue?.name}
                        {/* <span className='redTag'><img width="15px" src={process.env.PUBLIC_URL + 'images/pages/redTag_icon.svg'} alt="Schedule" />{userValue?.currentSadhnaStreak}</span> */}
                    </label>
                    {/* <span className="status online">Online</span> */}
                </div>
                <div id="scrollRight" style={{ maxHeight: "400px" }}>
                    {/* <Card className="userStatus">
                        <Card.Body>
                            <Card.Title>Sadhna <div className="progressOuter">
                                <ProgressBar now={40} key={3} />
                                <span className="valueCount"><span className="done">28</span>/<span className="total">35</span></span>
                            </div>
                            </Card.Title>
                            <Row>
                                <Col>
                                    <Button className="sadhna"><img src={process.env.PUBLIC_URL + 'images/pages/sadhna_icon.svg'} alt="edit_icon" /> Sadhna: {userValue?.targetSadhna}hr/ month </Button>
                                </Col>
                                <Col>
                                    <Button><img src={process.env.PUBLIC_URL + 'images/pages/calendar_icon.svg'} alt="edit_icon" />May : 24hr</Button>
                                </Col>
                            </Row>
                            <Row className="lastRow">
                                <Col>
                                    <Button><img src={process.env.PUBLIC_URL + 'images/pages/gray_link_icon.svg'} alt="edit_icon" />Current Streak: {userValue?.currentSadhnaStreak ? <>{userValue?.currentSadhnaStreak} days</> : "--"}</Button>
                                </Col>
                                <Col>
                                    <Button><img src={process.env.PUBLIC_URL + 'images/pages/gray_group_icon.svg'} alt="edit_icon" />Satsang: {userValue?.averageSatsangPerMonth ? <>{userValue?.averageSatsangPerMonth} / month</> : "--"}</Button>
                                </Col>
                            </Row>
                        </Card.Body>
                    </Card> */}
                    <Card>
                        <Card.Body>
                            <Card.Title>YPSS info <button onClick={() => editHandle("ypssInfo", "edit")} className="editProfile"><img className='userImg' src={process.env.PUBLIC_URL + 'images/sidebar/edit_icon.svg'} alt="edit_icon" />Edit</button></Card.Title>
                            <Row>
                                <Col>
                                    <label>Full Name</label>
                                    <span>{userValue?.name}</span>
                                </Col>
                                <Col>
                                    <label>UID</label>
                                    <span>{userValue?.uid}</span>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <label>Branch</label>
                                    <span>{userValue && userValue?.orgBranch.branchName ? userValue?.orgBranch.branchName : "--"} </span>
                                </Col>
                                <Col>
                                    <label>Role</label>
                                    <span>{userValue?.orgRole.name ? userValue.orgRole.name : "--"}</span>
                                </Col>
                            </Row>
                            {/* <Row className="lastRow">
                                <Col>
                                    <label>Team</label>
                                    <span>{userValue?.orgTeam && userValue.orgTeam?.name ? userValue.orgTeam?.name : "--"}</span>
                                </Col>
                                <Col>
                                    <label>Sub Team</label>
                                    <span>{userValue?.orgTeam && userValue.orgSubTeam?.name ? userValue.orgSubTeam?.name : "--"}</span>
                                </Col>
                            </Row> */}
                        </Card.Body>
                    </Card>
                    <Card>
                        <Card.Body>
                            <Card.Title>Personal info<button onClick={() => editHandle("personalInfo", "edit")} className="editProfile"><img className='userImg' src={process.env.PUBLIC_URL + 'images/sidebar/edit_icon.svg'} alt="edit_icon" />Edit</button></Card.Title>
                            <Row>
                                <Col>
                                    <label>Date of Birth</label>
                                    <span>{userValue?.dob ? userValue.dob : "--"}</span>
                                </Col>
                                <Col>
                                    <label>Address</label>
                                    <span>{userValue?.address ? userValue.address : "--"}</span>
                                </Col>
                            </Row>
                            <Row className="lastRow">
                                <Col>
                                    <label>Initiation Year</label>
                                    <span>{userValue?.initiationDate ? userValue.initiationDate : "--"}</span>
                                </Col>
                                <Col>
                                    <label>Blood Group</label>
                                    <span>{userValue?.bloodGroup ? userValue.bloodGroup : "--"}</span>
                                </Col>
                            </Row>
                        </Card.Body>
                    </Card>
                    <Card>
                        <Card.Body>
                            <Card.Title>Contact info<button onClick={() => editHandle("contactInfo", "edit")} className="editProfile"><img className='userImg' src={process.env.PUBLIC_URL + 'images/sidebar/edit_icon.svg'} alt="edit_icon" />Edit</button></Card.Title>
                            <Row>
                                <Col>
                                    <label>Contact no</label>
                                    <span>{userValue?.phone ? userValue.phone : "--"}</span>
                                </Col>
                                <Col>
                                    <label>Alternate Number</label>
                                    <span>{userValue?.alternatePhone ? userValue.alternatePhone : "--"}</span>
                                </Col>
                            </Row>
                            {/* <Row className="lastRow">
                                <Col>
                                    <label>WhatsApp No.</label>
                                    <span>{userValue?.phone ? userValue.phone : "--"}</span>
                                </Col>
                                <Col>
                                    <label>Email Address</label>
                                    <span>{userValue?.email ? userValue.email : "--"}</span>
                                </Col>
                            </Row> */}
                        </Card.Body>
                    </Card>
                    <Card>
                        <Card.Body>
                            <Card.Title>Education<button onClick={() => editHandle("education", "edit")} className="editProfile"><img className='userImg' src={process.env.PUBLIC_URL + 'images/sidebar/edit_icon.svg'} alt="edit_icon" />Edit</button></Card.Title>
                            <Row>
                                <Col>
                                    <label>Degree</label>
                                    <span>{userValue?.educationQual ? userValue.educationQual : "--"}</span>
                                </Col>
                                <Col>
                                    <label>Field of Study</label>
                                    <span>{userValue?.educationStream ? userValue.educationStream : "--"}</span>
                                </Col>
                            </Row>
                        </Card.Body>
                    </Card>
                    <Card>
                        <Card.Body>
                            <Card.Title>Occupation<button onClick={() => editHandle("occupation", "edit")} className="editProfile"><img className='userImg' src={process.env.PUBLIC_URL + 'images/sidebar/edit_icon.svg'} alt="edit_icon" />Edit</button></Card.Title>
                            <Row>
                                <Col>
                                    <label>Job Title</label>
                                    <span>{userValue?.occupation ? userValue.occupation : "--"}</span>
                                </Col>
                                <Col>
                                    <label>Sector</label>
                                    <span>{userValue?.sector ? userValue.sector : "--"}</span>
                                </Col>
                            </Row>
                            <Row className="lastRow">
                                <Col>
                                    <label>Skills</label>
                                    <span>{userValue?.skills ? userValue.skills : "--"}</span>
                                </Col>
                                <Col>
                                    <label>Skill Level</label>
                                    <span>{userValue?.skillsLevel ? userValue.skillsLevel : "--"}</span>
                                </Col>
                            </Row>
                        </Card.Body>
                    </Card>
                </div>
            </section>

            {showDeleteMOdal && <DeleteProfile show={showDeleteMOdal} hide={() => setShowDeleteMOdal(false)} />}
            {createProfileModal && <CreateProfileModal show={createProfileModal} hide={closeCreateProfile} section={createProfileModal.section} edit={createProfileModal.edit} data={createProfileModal.data} />}
        </>
    );
}

export default UserDetail;
