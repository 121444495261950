import { useEffect, useState } from "react";
import { Dropdown } from "react-bootstrap";
import { getDataWithAuth } from "../api/api";
import { useNavigate , useLocation} from "react-router-dom";
import { toast } from "react-toastify";

const LoggedinProfile = () => {
    const token = localStorage.getItem('token');
    const [profile, setProfile] = useState()
    const navigate = useNavigate();
    const location = useLocation();
    const fetchuserProfile = async () => {
        try {
            const response = await getDataWithAuth('api/user/details');
            if (response) {
                setProfile(response.data);
                localStorage.setItem('userProfile', JSON.stringify(response.data));
                if(response.data?.userRole !== "ADMIN" && location.pathname === '/userProfiles' || !response) {
                    navigate('/');
               }
            }
        } catch (error) {
            console.error('Error fetching data', error);
        }
    }

    const logout = () => {
        localStorage.removeItem("token");
        navigate('/');
    }

    


    useEffect(() => {
        fetchuserProfile()
        if(!token){
            navigate('/');  
        }
    }, [])
    return (
        <div className="profile">
            <div className="profileinfo">
                <img className="adminImg" src={profile && profile.imgUrl} />
                {/* <label>{profile && profile.name}
                    <span>Admin</span>
                </label> */}
            </div>
            <Dropdown className="customDropdown">
                <Dropdown.Toggle id="dropdown-basic">
                    <img className="filterIcon" src={process.env.PUBLIC_URL + 'images/pages/arrow_icon.svg'} alt="arrow icon" />
                </Dropdown.Toggle>
                <Dropdown.Menu>
                    <Dropdown.Item onClick={logout} >Logout</Dropdown.Item>
                </Dropdown.Menu>
            </Dropdown>
        </div>
    )
}

export default LoggedinProfile 