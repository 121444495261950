import { useEffect, useState } from "react";
import { Button, Form, Modal, Alert } from "react-bootstrap";
import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import ReactSelect from "react-select";
import UsualSewaBranch from '../selectBoxJson/UsualSewaBranch.json';
import UsualSewaNurmahal from '../selectBoxJson/UsualSewaNurmahal.json';
import Degree from '../selectBoxJson/Degree.json';
import FieldofStudy from '../selectBoxJson/FieldofStudy.json';
import JobTitle from '../selectBoxJson/JobTitle.json';
import Sector from '../selectBoxJson/Sector.json';
import Skills from '../selectBoxJson/Skills.json';
import SkillLevel from '../selectBoxJson/SkillLevel.json';
import { getDataWithAuth, postDataWithAuth, postDataWithoutAuth } from "../../api/api";
import moment from "moment";
import { API_URL } from "../../config/config";
import axios from "axios";


const CreateProfileModal = ({ show, hide, section, data, edit, onSave }) => {
    console.log(data, "datadatadatadata")
    const token = localStorage.getItem('token');
    const [ypssInfo, setYpssInfo] = useState(true);
    const [personalInfo, setPersonalInfo] = useState(false);
    const [contactInfo, setContactInfo] = useState(false);
    const [education, setEducation] = useState(false);
    const [uidShow, setUidShow] = useState(false);
    const [uid, setUid] = useState(false);
    const [errors, setErrors] = useState({});
    const [teams, setTeams] = useState([]);
    const [subTeams, setSubTeams] = useState([]);
    const [role, setRole] = useState([]);
    const [branches, setBranches] = useState([]);
    const [selectedTeam, setSelectedTeam] = useState(null);
    const [selectedSubTeam, setSelectedSubTeam] = useState(null);
    const [sewaInBranche, setSewaInBranche] = useState(UsualSewaBranch);
    const [sewaInNurmahal, setSewaInNurmahal] = useState(UsualSewaNurmahal);
    const [degree, setDegree] = useState(Degree);
    const [fieldofStudy, setFieldofStudy] = useState(FieldofStudy);
    const [jobTitle, setJobTitle] = useState(JobTitle);
    const [sector, setSector] = useState(Sector);
    const [skills, setSkills] = useState(Skills);
    const [skillLevel, setSkillLevel] = useState(SkillLevel);
    const [zone, setZone] = useState();
    const [areaCode, setAreaCode] = useState("");
    const [isBranchDisabled, setIsBranchDisabled] = useState(true);
    const [isAreaCodeDisabled, setIsAreaCodeDisabled] = useState(true);
    const [roleId, setRoleId] = useState("");
    const [areaCodeId, setAreaCodeId] = useState("");
    const [emailError, setEmailError] = useState(null);
    const [phoneError, setPhoneError] = useState(null);

    

    const [formData, setFormData] = useState({
        fullName: "",
        zone: "",
        branch: "",
        area: "",
        role: "",
        team: "",
        subTeam: "",
        usualSewaInBranch: "",
        usualSewaInNurmahal: "",
        birthDate: new Date(),
        address: "",
        initiationYear: new Date(),
        bloodGroup: "",
        contactNo: "",
        alternateNo: "",
        whatsappNo: "",
        email: "",
        degree: "",
        fieldOfStudy: "",
        jobTitle: "",
        sector: "",
        skills: "",
        skillLevel: ""
    });



    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };




    const handleDateChange = (name, date) => {
        setFormData({ ...formData, [name]: date });
    };

    const validateSection = (section) => {
        let valid = true;
        const newErrors = {};
        if (section === "ypssInfo") {
            if (!formData.fullName) {
                valid = false;
                newErrors.fullName = "Full Name is required";
            } else if (!formData.zone) {
                valid = false;
                newErrors.zone = "Please select zone";
            } else if (!formData.branch) {
                valid = false;
                newErrors.branch = "Please select branch";
            } else if (!formData.area) {
                valid = false;
                newErrors.area = "Please select Area";
            }else if (!formData.role) {
                valid = false;
                newErrors.role = "Please select Role";
            }
        }
        setErrors(newErrors);
        return valid;
    };

    const checkEmailExistence = async (email) => {
        try {
            const response = await getDataWithAuth(`admin/api/user/checkEmailExist/${email}`);
            console.log(response, response.email, email, "response")
            if (response.email == email) {
                setEmailError("Email already exists");
                return false;
            } else {
                setEmailError(null);
                return true;
            }
        } catch (error) {
            setEmailError("Email not valid");
            return false;
        }
    };

    const checkPhone = async (phone) => {
        try {
            const response = await getDataWithAuth(`admin/api/user/checkPhoneExist/${phone}`);
            console.log(response, response.phone, phone, "response phone")
            if (response.phone == phone) {
                setPhoneError("Phone already exists");
                return false;
            } else {
                setPhoneError(null);
                return true;
            }
        } catch (error) {
            setPhoneError("Email not valid");
            return false;
        }
    };

    const handleNext = async () => {
        if (ypssInfo) {
            if (validateSection("ypssInfo")) {
                setYpssInfo(false);
                setPersonalInfo(true);
            }
        } else if (personalInfo) {
            if (validateSection("personalInfo")) {
                setPersonalInfo(false);
                setContactInfo(true);
            }
        } else if (contactInfo) {
            if (edit) {
                setContactInfo(false);
                setEducation(true);
            } else {
                if (validateSection("contactInfo")) {
                    let canProceed = true;

                    if (formData.email) {
                        const isEmailValid = await checkEmailExistence(formData.email);
                        if (!isEmailValid) {
                            canProceed = false;
                        }
                    } else {
                        setEmailError("Email is required.");
                        canProceed = false;
                    }

                    if (formData.contactNo) {
                        const isPhoneValid = await checkPhone(formData.contactNo);
                        if (!isPhoneValid) {
                            canProceed = false;
                        }
                    } else {
                        setPhoneError("Phone number is required.");
                        canProceed = false;
                    }

                    if (canProceed) {
                        setContactInfo(false);
                        setEducation(true);
                    }
                }
            }

        }
    };



    const handlePrevious = () => {
        if (education) {
            setEducation(false);
            setContactInfo(true);
        } else if (contactInfo) {
            setContactInfo(false);
            setPersonalInfo(true);
        } else if (personalInfo) {
            setPersonalInfo(false);
            setYpssInfo(true);
        }
    };


    // https://teamypss.com/admin/api/zone/list'

    // 'https://teamypss.com/admin/api/branch/list?zoneId=2' 
    // https://teamypss.com/admin/api/areacodes/list?branchId=66' 


    const fetchzone = async () => {
        try {
            const response = await getDataWithAuth('admin/api/zone/list');
            const options = response.data.map(zone => ({ value: zone.id, label: zone.name }));
            setZone(options);
            if (edit && data?.orgZone) {
                const zoneValue = options.find(zone => zone.value === data.orgZone.id);
                setFormData(prevState => ({ ...prevState, zone: zoneValue }));
                zoneHandle(zoneValue);
            }
        } catch (error) {
            console.error('Error fetching zones', error);
        }
    };

    const zoneHandle = (selectedOption) => {
        setBranches([]);
        setAreaCode([]);
        fetchBranches(selectedOption.value);
        setFormData(prevState => ({ ...prevState, zone: selectedOption }));
        setIsBranchDisabled(false);
    };


    const fetchBranches = async (zoneId) => {
        try {
            const response = await getDataWithAuth(`admin/api/branch/list?zoneId=${zoneId}`);
            const options = response.data.map(branch => ({ value: branch.id, label: branch.branchName }));
            setBranches(options);
            if (edit && data?.orgBranch) {
                const branchValue = options.find(branch => branch.value === data.orgBranch.id);
                setFormData(prevState => ({ ...prevState, branch: branchValue }));
                branchHandle(branchValue);
            }
        } catch (error) {
            console.error('Error fetching branches', error);
        }
    };

    const branchHandle = (selectedOption) => {
        setAreaCode([]);
        fetchAreacodes(selectedOption.value);
        setFormData(prevState => ({ ...prevState, branch: selectedOption }));
        setIsAreaCodeDisabled(false);
    };

    const fetchAreacodes = async (branchId) => {
        try {
            const response = await getDataWithAuth(`admin/api/areacodes/list?branchId=${branchId}`);
            const options = response.data.map(areaCode => ({ value: areaCode.id, label: areaCode.areaCode }));
            setAreaCode(options);
            if (edit && data?.orgAreaCode) {
                const areaCodeValue = options.find(areaCode => areaCode.value === data.orgAreaCode.id);
                setFormData(prevState => ({ ...prevState, area: areaCodeValue }));
                setAreaCodeId(areaCodeValue.value);
            }
        } catch (error) {
            console.error('Error fetching area codes', error);
        }
    };


    const areaHandle = (selectedOption) => {
        setAreaCodeId(selectedOption.value);
        setFormData(prevState => ({ ...prevState, area: selectedOption }));
    }



    const fetchTeams = async () => {
        try {
            const response = await getDataWithAuth('admin/api/organization/team/list');
            const options = response.data.map(team => ({ value: team.id, label: team.name }))
            options.unshift({ value: 0, label: 'None' });
            setTeams(options)
            if (edit) {
                const teamValue = options.find(team => team.value == data.orgTeam.id)
                setFormData(prevState => ({ ...prevState, team: teamValue }))
                // handleTeamChange(teamValue)
            }
        } catch (error) {
            console.error('Error fetching data', error);
        }
    };

    const handleTeamChange = (selectedOption) => {
        setSubTeams([]);
        setSelectedTeam(selectedOption);
        setFormData(prevState => ({ ...prevState, team: selectedOption }));
        // if (selectedOption) {
        //     fetchSubTeams(selectedOption.value);
        // } else {
        //     setSubTeams([]);
        // }
    };

    const fetchSubTeams = async (parentId) => {
        try {
            const response = await getDataWithAuth(`admin/api/organization/team/list?parentId=${parentId ? parentId : ""}`);
            if (response.data && response.data.length > 0) {
                setSubTeams(response.data.map(team => ({ value: team.id, label: team.name })));
            } else {
                setSubTeams([]);
            }
        } catch (error) {
            console.error('Error fetching data', error);
            setSubTeams([]);
        }
    };




    const fetchRole = async () => {
        try {
            const response = await getDataWithAuth('admin/api/organization/role/list');
            if (response) {
                const roles = response.data.map(role => ({ value: role.id, label: role.name }));
                roles.unshift({ value: 0, label: 'None' });
                setRole(roles);
                if (edit) {
                    const roleValue = roles.find(role => role.value == data.orgRole.id);
                    setFormData(prevState => ({ ...prevState, role: roleValue }));
                }
            }
        } catch (error) {
            console.error('Error fetching data', error);
        }
    };




    const roleHandle = (selectedOption) => {
        setRoleId(selectedOption.value);
        setFormData(prevState => ({ ...prevState, role: selectedOption }));
    }



    const handleSelectChange = (name, value) => {
        if (name === "skills") {
            const selectedValues = value ? value.map(option => option.label) : [];
            setFormData(prevState => ({ ...prevState, [name]: selectedValues }));
        }
        else {
            setFormData(prevState => ({ ...prevState, [name]: value ? value.label : "" }));
        }
    };

    useEffect(() => {
        fetchTeams();
        fetchRole();
        fetchzone()
    }, [subTeams]);


    // function formatDate(inputDate) {
    //     if (!inputDate || typeof inputDate !== 'string') {
    //         console.error('Invalid date:', inputDate);
    //         return null; // Return null or handle accordingly if inputDate is invalid
    //     }
    //     const [day, month, year] = inputDate.split('-');
        
    //     if (!day || !month || !year) {
    //         console.error('Invalid date format:', inputDate);
    //         return null; // Handle invalid date format
    //     }
        
    //     const date = new Date(`${year}-${month}-${day}T00:00:00.000Z`);
    //     return date?.toISOString();
    // }

    function formatDate(inputDate) {
        if (!inputDate || typeof inputDate !== 'string') {
            console.error('Invalid date:', inputDate);
            return null; // Handle invalid input
        }
    
        // Normalize separators (handle both '-' and '/')
        const normalizedDate = inputDate.replace(/[/\-]/g, '-');
        const parts = normalizedDate.split('-').map(Number); // Convert parts to numbers
    
        let year, month, day;
    
        // Detect and assign values based on input length
        if (parts[0] > 31) { 
            // Case: yyyy-mm-dd
            [year, month, day] = parts;
        } else if (parts[2] > 31) { 
            // Case: dd-mm-yyyy
            [day, month, year] = parts;
        } else {
            // Case: mm-dd-yyyy
            [month, day, year] = parts;
        }
    
        // Adjust month (JavaScript months are 0-indexed)
        const date = new Date(Date.UTC(year, month - 1, day));
    
        if (isNaN(date.getTime())) {
            console.error('Invalid date format:', inputDate);
            return null; // Handle invalid date creation
        }
    
        return date.toISOString();
    }
    
    


    useEffect(() => {

        if (edit) {
            setUid(data?.id);
            console.log(data, "data?.data?.nurmahalSewadata?.nurmahalSewadata?.nurmahalSewa")
            setFormData({
                fullName: data?.name,
                usualSewaInBranch: data?.branchSewa,
                usualSewaInNurmahal: data?.nurmahalSewa,
                role:data?.nurmahalSewa,
                birthDate: formatDate(data?.dob),
                address: data?.address,
                initiationYear: new Date(moment(data?.initiationDate).format('YYYY')),
                bloodGroup: data?.bloodGroup,
                contactNo: data?.phone,
                alternateNo: data?.alternatePhone,
                whatsappNo: data?.phone,
                email: data?.email,
                degree: data?.educationQual,
                fieldOfStudy: data?.educationStream,
                jobTitle: data?.occupation,
                sector: data?.sector,
                // skills: data?.skills,
                skills: data?.skills 
                ? data.skills.split(', ').map(skill => ({ label: skill, value: skill })) 
                : [],
                skillLevel: data?.skillsLevel,
            });
        }
    }, [section, edit,]);

    const handleSubmit = async () => {
        const skills = Array.isArray(formData.skills) ? formData.skills : [];
        const formattedSkills = skills.length > 0 ? skills.join(', ') : '';

        // area: "",
        // role: "",
        let param =
            JSON.stringify(
                {
                    uid: edit ? data?.uid : "",
                    name: formData.fullName,
                    areaCodeId: areaCodeId,
                    userRole: "VOLUNTEER",
                    orgRole: roleId, //role select box
                    // orgTeam: selectedTeam ? selectedTeam.value : null,
                    // subTeam: selectedSubTeam ? selectedSubTeam.value : null,
                    // branchSewa: formData.usualSewaInBranch,
                    // nurmahalSewa: formData.usualSewaInNurmahal,
                    dob: moment(formData.birthDate).format('DD-MM-YYYY'),
                    address: formData.address,
                    initiationDate: moment(formData.initiationYear).format('YYYY'),
                    bloodGroup: formData.bloodGroup,
                    phone: formData.contactNo,
                    alternatePhone: formData.alternateNo,
                    // whatsappNo: formData.whatsappNo,
                    email: formData.email,
                    educationQual: formData.degree,
                    educationStream: formData.fieldOfStudy,
                    occupation: formData.jobTitle,
                    sector: formData.sector,
                    skills: formattedSkills && formattedSkills,
                    skillsLevel: formData.skillLevel,
                }
            )
            ;
        console.log(param, "param paramparamparamparamparam");

        const headers = {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        };
        if (validateSection("education")) {
            try {
                const response =
                    edit ?
                        await axios.put(`${API_URL}admin/api/user/updateUser`, param, { headers })
                        :
                        await axios.post(`${API_URL}admin/api/user/create`, param, { headers })
                if (response.status === 200 || response.status === 201) {
                    const userId = response.data.data.uid;
                    setUidShow(true);
                    setUid(userId);
                    if (onSave) {
                        onSave();
                    }
                }
            } catch (error) {
                console.error("Error submitting the form", error);
            }
        }
    };






    return (
        <>
            <Modal show={show} className="createUserProfile" onHide={hide}>
                {uidShow && uidShow ? (
                    <div className="createUid">
                        <img src={process.env.PUBLIC_URL + 'images/pages/tick_icon.svg'} alt="edit_icon" />
                        {edit &&
                            edit ?
                            <p>Updations Completed</p>
                            :
                            <>
                                <label className="uid">{uid}</label>
                                <p>Congratulations! You are now a<br /> member of team YPSS.</p>
                            </>}
                        <Button variant="primary" className="done" onClick={hide}>
                            Done
                        </Button>
                    </div>
                )
                    :

                    <>
                        {ypssInfo && (
                            <>
                                <Modal.Header>
                                    <Modal.Title>
                                        {ypssInfo && "YPSS Info"}
                                        <div className="d-flex align-items-center">
                                            <button className="tabsBtn active"></button>
                                            <button className="tabsBtn"></button>
                                            <button className="tabsBtn"></button>
                                            <button className="tabsBtn"></button>
                                        </div>
                                    </Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    <Form>
                                        <Form.Group className="mb-2">
                                            <Form.Label>Full Name</Form.Label>
                                            <Form.Control
                                                type="text"
                                                name="fullName"
                                                value={formData.fullName}
                                                onChange={handleChange}
                                            />
                                            {errors.fullName && <Alert variant="danger">{errors.fullName}</Alert>}
                                        </Form.Group>
                                        <Form.Group className="mb-2">
                                            <Form.Label>Zone</Form.Label>
                                            <ReactSelect
                                                options={zone}
                                                placeholder="Select"
                                                name="zone"
                                                value={formData.zone}
                                                onChange={zoneHandle}
                                            />
                                            {errors.zone && <Alert variant="danger">{errors.zone}</Alert>}
                                        </Form.Group>
                                        <Form.Group className="mb-2">
                                            <Form.Label>Branch</Form.Label>
                                            <ReactSelect
                                                options={branches}
                                                placeholder="Select"
                                                name="branch"
                                                onChange={branchHandle}
                                                isClearable
                                                isDisabled={formData.branch ? "" : isBranchDisabled}
                                                value={formData.branch}
                                            />
                                            {errors.branch && <Alert variant="danger">{errors.branch}</Alert>}
                                        </Form.Group>
                                        <Form.Group className="mb-2">
                                            <Form.Label>Area Code</Form.Label>
                                            <ReactSelect
                                                options={areaCode}
                                                placeholder="Select"
                                                name="area"
                                                onChange={areaHandle}
                                                isDisabled={formData.area ? "" : isAreaCodeDisabled}
                                                value={formData.area}
                                            />
                                            {errors.area && <Alert variant="danger">{errors.area}</Alert>}
                                        </Form.Group>

                                        <Form.Group className="mb-2">
                                            {console.log(formData.role, "formData.role")}
                                            <Form.Label>Role</Form.Label>
                                            <ReactSelect
                                                closeMenuOnSelect={true}
                                                options={role}
                                                placeholder="Select Role"
                                                name="role"
                                                value={formData.role}
                                                // onChange={(value) => handleSelectChange("role", value)}
                                                onChange={roleHandle}
                                            />
                                            {errors.role && <Alert variant="danger">{errors.role}</Alert>}
                                        </Form.Group>
                                        {/* <Form.Group className="mb-2">
                                            <Form.Label>Team</Form.Label>
                                            <ReactSelect
                                                name="team"
                                                value={formData.team}
                                                onChange={handleTeamChange}
                                                options={teams}
                                                placeholder="Select a Team"
                                            />
                                            {errors.team && <Alert variant="danger">{errors.team}</Alert>}
                                        </Form.Group> */}

                                        {subTeams.length > 0 && (
                                            <Form.Group className="mb-2">
                                                <Form.Label>Sub-Team</Form.Label>
                                                <ReactSelect
                                                    name="subTeam"
                                                    defaultValue={subTeams.find(option => option.label === (selectedSubTeam ? selectedSubTeam.label : null))}
                                                    onChange={(option) => {
                                                        setSelectedSubTeam(option);
                                                        setFormData({ ...formData, subTeam: option ? option.label : "" });
                                                    }}
                                                    options={subTeams}
                                                    placeholder="Select a Sub-Team"
                                                />
                                            </Form.Group>
                                        )}

                                        {/* <Form.Group className="mb-2">
                                            <Form.Label>Usual Sewa In Branch</Form.Label>
                                            <ReactSelect
                                                options={sewaInBranche}
                                                placeholder="Select Area"
                                                name="usualSewaInBranch"
                                                value={sewaInBranche.find(option => option.label === formData?.usualSewaInBranch) || null}
                                                onChange={(selectedOption) => handleSelectChange("usualSewaInBranch", selectedOption)}
                                            />
                                        </Form.Group> */}
                                        {/* <Form.Group className="mb-2">
                                            <Form.Label>Usual Sewa In Nurmahal</Form.Label>
                                            {console.log(typeof formData?.usualSewaInNurmahal)}
                                            <ReactSelect
                                                options={sewaInNurmahal}
                                                placeholder="Select sewa"
                                                name="usualSewaInNurmahal"
                                                value={sewaInNurmahal.find(option => option.label === formData?.usualSewaInNurmahal) || null}
                                                onChange={(selectedOption) => handleSelectChange("usualSewaInNurmahal", selectedOption)}
                                                isClearable
                                            />
                                        </Form.Group> */}
                                    </Form>
                                </Modal.Body>
                            </>
                        )}
                        {personalInfo && (
                            <>
                                <Modal.Header>
                                    <Modal.Title>
                                        {personalInfo && "Personal Info"}
                                        <div className="d-flex align-items-center">
                                            <button className="tabsBtn active"></button>
                                            <button className="tabsBtn active"></button>
                                            <button className="tabsBtn"></button>
                                            <button className="tabsBtn"></button>
                                        </div>
                                    </Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    <Form>
                                        <Form.Group className="mb-2">
                                            <Form.Label>Date of Birth</Form.Label>
                                            <ReactDatePicker
                                                selected={formData?.birthDate}
                                                onChange={(date) => handleDateChange("birthDate", date)}
                                                portalId="root-portal"
                                                className="form-control"
                                                showYearDropdown
                                                yearDropdownItemNumber={100} // Number of years to show in the dropdown
                                                scrollableYearDropdown
                                            />
                                        </Form.Group>
                                        <Form.Group className="mb-2">
                                            <Form.Label>Address</Form.Label>
                                            <Form.Control
                                                type="text"
                                                name="address"
                                                value={formData?.address}
                                                onChange={handleChange}
                                                as="textarea"
                                                rows={2}
                                            />
                                        </Form.Group>
                                        <Form.Group className="mb-2">
                                            <Form.Label>Initiation Year</Form.Label>
                                            <ReactDatePicker
                                                selected={formData?.initiationYear}
                                                onChange={(date) => handleDateChange("initiationYear", date)}
                                                portalId="root-portal"
                                                className="form-control"
                                                showYearPicker
                                                dateFormat={"yyyy"}
                                            />
                                        </Form.Group>
                                        <Form.Group className="mb-2">
                                            <Form.Label>Blood Group</Form.Label>
                                            <Form.Control
                                                type="text"
                                                name="bloodGroup"
                                                value={formData.bloodGroup}
                                                onChange={handleChange}
                                            />
                                        </Form.Group>
                                    </Form>
                                </Modal.Body>
                            </>
                        )}
                        {contactInfo && (
                            <>
                                <Modal.Header>
                                    <Modal.Title>
                                        {contactInfo && "Contact Info"}
                                        <div className="d-flex align-items-center">
                                            <button className="tabsBtn active"></button>
                                            <button className="tabsBtn active"></button>
                                            <button className="tabsBtn active"></button>
                                            <button className="tabsBtn"></button>
                                        </div>
                                    </Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    <Form>
                                        <Form.Group className="mb-2">
                                            <Form.Label>Contact no</Form.Label>
                                            <Form.Control
                                                type="text"
                                                name="contactNo"
                                                value={formData.contactNo}
                                                onChange={handleChange}
                                            />
                                            {phoneError && <Alert variant="danger">{phoneError}</Alert>}
                                        </Form.Group>
                                        <Form.Group className="mb-2">
                                            <Form.Label>Alternate Number</Form.Label>
                                            <Form.Control
                                                type="text"
                                                name="alternateNo"
                                                value={formData.alternateNo}
                                                onChange={handleChange}
                                            />
                                        </Form.Group>
                                        {/* <Form.Group className="mb-2">
                                            <Form.Label>WhatsApp No.</Form.Label>
                                            <Form.Control
                                                type="text"
                                                name="whatsappNo"
                                                value={formData.whatsappNo}
                                                onChange={handleChange}
                                            />
                                        </Form.Group> */}
                                        <Form.Group className="mb-2">
                                            <Form.Label>Email Address</Form.Label>
                                            <Form.Control
                                                type="text"
                                                name="email"
                                                value={formData.email}
                                                onChange={handleChange}
                                            />
                                            {emailError && <Alert variant="danger">{emailError}</Alert>}
                                        </Form.Group>
                                    </Form>
                                </Modal.Body>
                            </>
                        )}
                        {education && (
                            <>
                                <Modal.Header>
                                    <Modal.Title>
                                        {education && "Education"}
                                        <div className="d-flex align-items-center">
                                            <button className="tabsBtn active"></button>
                                            <button className="tabsBtn active"></button>
                                            <button className="tabsBtn active"></button>
                                            <button className="tabsBtn active"></button>
                                        </div>
                                    </Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    <Form>
                                        <Form.Group className="mb-2">
                                            <Form.Label>Degree</Form.Label>
                                            <ReactSelect
                                                options={degree}
                                                placeholder="Select degree"
                                                name="degree"
                                                value={degree.find(option => option.label == formData.degree)}
                                                onChange={(selectedOption) => handleSelectChange("degree", selectedOption)}
                                                isClearable
                                            />
                                        </Form.Group>
                                        <Form.Group className="mb-2">
                                            <Form.Label>Field of Study</Form.Label>
                                            <ReactSelect
                                                options={fieldofStudy}
                                                placeholder="Select Field of Study"
                                                name="fieldofStudy"
                                                defaultValue={fieldofStudy.find(option => option.label == formData.fieldOfStudy) || null}
                                                // defaultValue={fieldofStudy.find(option => option.label === formData.fieldOfStudy) || null}
                                                onChange={(selectedOption) => handleSelectChange("fieldOfStudy", selectedOption)}
                                                isClearable
                                            />
                                        </Form.Group>
                                        <Form.Group className="mb-2">
                                            <Form.Label>Job Title</Form.Label>
                                            <ReactSelect
                                                options={jobTitle}
                                                placeholder="Select Job Title"
                                                name="jobTitle"
                                                defaultValue={jobTitle.find(option => option.label == formData?.jobTitle)}
                                                onChange={(selectedOption) => handleSelectChange("jobTitle", selectedOption)}
                                                isClearable
                                            />
                                        </Form.Group>
                                        <Form.Group className="mb-2">
                                            <Form.Label>Sector</Form.Label>
                                            <ReactSelect
                                                options={sector}
                                                placeholder="Select Sector"
                                                name="sector"
                                                defaultValue={sector.find(option => option.label == formData?.sector)}
                                                onChange={(selectedOption) => handleSelectChange("sector", selectedOption)}
                                                isClearable
                                            />
                                        </Form.Group>
                                        <Form.Group className="mb-2">
                                            <Form.Label>Skills</Form.Label>
                                            <ReactSelect
                                                options={skills}
                                                placeholder="Select Skills"
                                                name="skills"
                                                defaultValue={formData.skills}
                                                // defaultValue={skills.find(option => option.label == formData?.skills)}
                                                onChange={(selectedOption) => handleSelectChange("skills", selectedOption)}
                                                isClearable
                                                isMulti
                                            />
                                        </Form.Group>
                                        <Form.Group className="mb-2">
                                            <Form.Label>Skill Level</Form.Label>
                                            <ReactSelect
                                                options={skillLevel}
                                                placeholder="Select Skill Level"
                                                name="skillLevel"
                                                defaultValue={skillLevel.find(option => option.label == formData?.skillLevel)}
                                                onChange={(selectedOption) => handleSelectChange("skillLevel", selectedOption)}
                                                isClearable
                                            />
                                        </Form.Group>

                                    </Form>
                                </Modal.Body>
                            </>
                        )}

                        <Modal.Footer>
                            <Button className="backArrow" onClick={handlePrevious}>
                                <img src={process.env.PUBLIC_URL + 'images/pages/backArrow_icon.svg'} alt="edit_icon" />
                            </Button>
                            <div>
                                <Button variant="secondary" className="me-2" onClick={hide}>
                                    Close
                                </Button>
                                {education && education ? <Button variant="primary" className="submit" onClick={handleSubmit}>
                                    Submit
                                </Button> : <>
                                    <Button variant="primary" className="continue" onClick={handleNext}>
                                        Continue
                                    </Button></>
                                }
                            </div>
                        </Modal.Footer>
                    </>
                }
            </Modal >
        </>
    );
};

export default CreateProfileModal;
